function init_header() {
  // Mobile hambuger
  $('.js-toggle-mobile-menu').on('click', function() {
    var $body = $('body');
    var $hamburger = $('.js-hamburger');

    $body.toggleClass('mobile-menu-open');
    $hamburger.toggleClass('is-active');
  });

  // Desktop handlers
  if($(window).width() >= 1220) {
    $('li.menu-item-has-children').mouseenter(function() {
        var $el = $(this);
        var $dropdown = $el.children('.sub-menu');

        if($dropdown.length) {
          // $el.children('a').css('background-color', '#fff');
          // $el.children('a').css('color', '#000');
          $dropdown.css('opacity', '1');
          $dropdown.css('pointer-events', 'auto');
        }
    });
    $('li.menu-item-has-children').mouseleave(function() {
      var $el = $(this);
      var $dropdown = $el.children('.sub-menu');

      if($dropdown.length) {
        // $el.children('a').css('background-color', 'transparent');
        // $el.children('a').css('color', '#fff');
        $dropdown.css('opacity', '0');
        $dropdown.css('pointer-events', 'none');
      }
    });
  }

  // Mobile handlers
  // else {
  //   $('.level-1').click(function() {
  //       var $clicked = $(this);
  //       var $dropdown = $clicked.children('.dropdown-menu');

  //       if($dropdown.length) {
  //         $clicked.children('.nav-link').css('background-color', '#fff');
  //         $clicked.children('.nav-link').css('color', '#000');
  //         $dropdown.css('opacity', '1');
  //         $dropdown.css('pointer-events', 'auto');
  //       }
  //   });
  // }
}

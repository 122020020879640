var myLazyLoad = null;
jQuery(document).ready(function($) {
    myLazyLoad = new LazyLoad();
    if(myLazyLoad) {
        myLazyLoad.update();
    }

    // Inits here
    init_header();

    $('body').addClass('page-loaded');
});

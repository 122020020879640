function smooth_scroll(target_px, speed) {
    if(typeof target_px == undefined) {
        console.warn('Tried smooth scrolling, but no target height was given!');
        return false;
    }

    if(!speed) {
        speed = 500;
    }

    $('html, body').animate({ scrollTop: target_px }, speed);
}
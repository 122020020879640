function init_obfuscate() {
    $obfuscated_links = $('a.js-obfuscate');

    $obfuscated_links.on('click', function(e) {
        e.preventDefault();
        var $link = $(this);
        var target_href = $link.attr('data-href');

        if(target_href) {
            // Get and decode
            window.location.href = atob(target_href);
        }
        else {
            console.error('You clicked an obfuscated link, but no data-href attribute was found.');
        }
    });
}

jQuery(document).ready(function($) {
    init_obfuscate();
});
